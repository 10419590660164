@font-face {
  font-family: alenbiRegular;
  src: url("../../assets/fonts/alenbiserifregular.otf");
}
@font-face {
  font-family: alenbiBold;
  src: url("../../assets/fonts/alenbiserifbold.otf");
}
@font-face {
  font-family: assistant;
  src: url("../../assets/fonts/assistant.ttf");
}
html,
body {
  font-family: alenbiRegular;
  overflow-x: hidden;
  position: relative;
  float: left;
}
.inline-image {
  height: 100%;
}
