@import "../../assets/utils/functions.scss";
.main {
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-position: center;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(0, 0, 0, 0.5)
  );
}
#video_background {
  position: absolute;
  z-index: -1;
  object-fit: cover;
  height: 100%;
  width: 177.77777778vh; /* 100 * 16 / 9 */
  min-width: 100%;
  min-height: 56.25vw; /* 100 * 9 / 16 */
}
.headerBackground {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
}
.headerText {
  color: white;
  font-size: vh_d(41);
  line-height: 100%;
  margin: 0;
  text-align: center;
}
.ynetInline {
  height: vh_d(38);
  transform: translateY(vw_d(8));
}
.headerDate {
  font-size: vh_d(132);
  line-height: 90%;
  font-weight: 700;
  text-align: center;
  color: white;
  margin: 0;
  margin-bottom: vh_d(40);
}

.headerText2 {
  color: white;
  font-size: vh_d(48);
  margin: 0;
  text-align: center;
}
.bold {
  font-weight: 700;
}

.redCircle {
  background-color: red;
  padding: 0 12px;
  border-radius: 50%;
}
.headerImage {
  height: vh_d(236);
  margin-top: vh_d(173);
  margin-bottom: vh_d(147);
}
.downIcon {
  margin-top: vh_d(42);
  height: vh_d(42);
  animation-name: mymove;
  animation-duration: 0.4s;
  animation-timing-function: ease-in;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  cursor: pointer;
}

@keyframes mymove {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(20px);
  }
}

@media (max-width: 640px) {
  .headerImage {
    width: vw(485);
    height: auto;
    margin-top: vw(212);
    margin-bottom: vw(202);
  }
  .headerText,
  .headerText2 {
    font-size: vw(37);
  }
  .headerDate {
    font-size: vw(129);
    margin-top: vw(22);
    margin-bottom: vw(18);
  }
  .downIcon {
    margin-top: vw(72);
  }
  .ynetInline {
    margin-top: vw(20);
    height: vw(36);
    transform: translateY(vw(10));
  }
}
