@import "../../assets/utils/functions.scss";

.speakerCard {
  margin: 0 vw_d(16);
}

.imageContainer {
  border-radius: vw_d(81);
  overflow: hidden;
  width: vw_d(288);
  height: vw_d(288);
  background-color: lightgray;
}
.image {
  width: 100%;
}
.name {
  color: #2f8bc9;
  text-align: center;
  margin: vw_d(16) 0 vw_d(8) 0;
  font-size: vw_d(32);
}
.title {
  color: #2f8bc9;
  text-align: center;
  margin: 0;
  font-size: vw_d(24);
}
@media (max-width: 640px) {
  .image {
    width: 100%;
  }
  .imageContainer {
    border-radius: vw(48);
    width: vw(180);
    height: vw(180);
  }
  .name {
    font-size: vw(25);
  }
  .title {
    font-size: vw(17);
  }
}
