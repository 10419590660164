@import "../../assets/utils/functions.scss";

.speakerCard {
  margin: 0 auto;
  margin-bottom: vw_d(62);
  width: vw_d(225);
}

.imageContainer {
  border-radius: vw_d(81);
  overflow: hidden;
  background-color: #cdcecf;
  width: vw_d(225);
  height: vw_d(225);
  display: flex;
  align-items: center;
  justify-content: center;
}
.image {
  height: 100%;
}
.name {
  color: #2f8bc9;
  text-align: center;
  margin: vw_d(14) 0 0 0;
  font-size: vw_d(25);
  width: 100%;
  word-wrap: break-word;
}
.title {
  color: #2f8bc9;
  text-align: center;
  margin: vw_d(8) 0 0 0;
  font-size: vw_d(20);
}
@media (max-width: 640px) {
  .speakerCard {
    width: vw(157);
  }
  .image {
    height: 100%;
  }
  .imageContainer {
    border-radius: vw(45);
    width: vw(157);
    height: vw(157);
  }
  .name {
    font-size: vw(20);
    margin: vw(8) 0 0 0;
  }
  .title {
    font-size: vw(15);
    margin: vw(4) 0 0 0;
  }
}
