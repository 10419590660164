@import "../../assets/utils/functions.scss";

.overlay {
  top: 0;
  bottom: 0;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  z-index: 10000;
  transition: 0.4s;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup {
  width: vw_d(1024);
  margin: auto;
  position: relative;
  overflow-y: auto;
  .agenda {
    width: 100%;
  }
}
.title {
  margin-top: vw_d(73);
  h1 {
    font-size: vw_d(40);
    font-weight: 400;
    color: #2f8bc9;
    margin: 0;
    text-align: center;
  }
}
.closeIcon {
  position: fixed;
  z-index: 9999999;
  top: vw_d(40);
  right: vw_d(486);
  width: vw_d(42);
  cursor: pointer;
}

@media (max-width: 640px) {
  .popup {
    width: vw(510);
    .agenda {
      width: 100%;
    }
  }
  .title {
    margin-top: vw(90);
    padding: 0 vw(90);
    h1 {
      font-size: vw(35);
    }
  }
  .closeIcon {
    width: vw(31);
    top: vw(28.2);
    right: vw(91.2);
  }
}
