@import "../../assets/utils/functions.scss";

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.w100 {
  width: 100%;
}

.listHeader {
  margin-top: vw_d(80);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.titleBox {
  padding: vw_d(4) vw_d(54);
  background-color: #2f8bc9;
  margin: 0 vw_d(24);
}

.headerLine {
  border-color: #2f8bc9;
  width: 100%;
}

.headerTitle {
  color: white;
  font-size: vw_d(38);
  font-weight: 400;
  margin: 0;
}

.description,
.description:visited {
  text-decoration: none;
  color: red;
  font-size: vw_d(24);
  font-weight: 400;
  margin: 0;
  margin-top: vw_d(8);
  margin-bottom: vw_d(78);
}
@media (max-width: 640px) {
  .listHeader {
    margin-top: vw(44);
  }
  .titleBox {
    padding: vw(6) vw(37);
    margin: 0 vw(12);
  }
  .headerTitle {
    font-size: vw(22);
  }
  .description {
    font-size: vw(17);
    margin-bottom: vw(50);
  }
}
