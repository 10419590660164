@import "../../assets/utils/functions.scss";

.main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: vw_d(0);
}
.w100 {
  width: 100%;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.title {
  color: red;
  font-size: vw_d(25);
  margin: 0 vw_d(12);
  font-weight: 400;
  cursor: pointer;
}
.line {
  flex: 1;
  border-color: #2f8bc9;
  border-width: 1px;
}
.description {
  font-size: vw_d(15);
  color: #2f8bc9;
  margin-top: vw_d(45);
  margin-bottom: vw_d(28);
}
.partners {
  margin: vw_d(7) vw_d(6);
  height: vw_d(43);
}
.sponsors {
  margin: vw_d(7) vw_d(12);
  height: vw_d(52);
}
.partnersContainer {
  width: 100%;
  margin-top: vw_d(35);
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.endCredits {
  font-size: vw_d(15);
  color: #2f8bc9;
  text-align: center;
  margin: vw_d(48) 0 vw_d(64) 0;
}
.sponsorContainer {
  margin-top: vw_d(35);
  flex-direction: row;
  display: flex;
  justify-content: center;
  margin-bottom: vw_d(36);
}
@media (max-width: 640px) {
  .title {
    font-size: vw(15);
    margin: 0 vw(12);
  }
  .description {
    font-size: vw(12);
    padding: vw(38) vw(39) vw(27) vw(39);
  }
  .partners {
    margin: vw(7) vw(6);
    height: vw(39);
  }
  .sponsors {
    margin: vw(7) vw(12);
    height: vw(47);
  }
  .endCredits {
    font-size: vw(12);
    margin: vw(24) 0 vw(64) 0;
  }
  .partnersContainer {
    margin-bottom: vw(20);
  }
  .sponsorContainer {
    margin-bottom: vw(20);
  }
}
